@import "src/styles/helpers";

$max-logo-height: 32px;

.wrapper {
  display: flex;
  align-items: center;

  img {
    width: auto;
    height: auto;
    max-height: 25px;

    @include media(tablet) {
      max-height: $max-logo-height;
    }

    @include media(desktop-small) {
      max-height: $max-logo-height;
    }
    @include media(desktop) {
      max-height: $max-logo-height;
    }
  }
}

.text {
  margin-top: auto;
  color: var(--primary-color);
  font-family: Roboto-Regular, serif;
  font-size: 25px;
  font-weight: 500;
}